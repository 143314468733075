<template>
    <div v-if="voucher !== false">
        <div :class="{ 'private-foundation-courses__widget-title-area': true, 'private-foundation-courses__widget-title-area--past': voucher.past == 1}">
            <h2 v-if="voucher.past == 0">Private Foundations Course with {{ voucher.teacher_name }}</h2>
            <h2 v-else>Past Private Foundations Course with {{ voucher.teacher_name }}</h2>
        </div>
        <div class="private-foundation-courses__classes-container">
            <class-slot :slot-number="1" :voucher="voucher" :reservation="firstClass"></class-slot>
            <class-slot :slot-number="2" :voucher="voucher" :reservation="secondClass"></class-slot>
            <class-slot :slot-number="3" :voucher="voucher" :reservation="thirdClass"></class-slot>
            <class-slot :slot-number="4" :voucher="voucher" :reservation="fourthClass"></class-slot>
            <class-slot :slot-number="5" :voucher="voucher" :reservation="fifthClass"></class-slot>
            <class-slot :slot-number="6" :voucher="voucher" :reservation="sixthClass"></class-slot>
        </div>
    </div>
</template>

<script>
import ClassSlot from './ClassSlot';

export default {
    components: {
        ClassSlot,
    },
    props: {
        voucher: {
            type: Object,
            default: false,
            required: true,
        },
    },
    store: {
        vouchers() {
            return 'vouchers';
        },
        allReservations() {
            return 'reservations';
        },
    },
    computed: {
        reservations() {
            return this.allReservations[this.voucher.id] || [];
        },
        firstClass() {
            return this.reservations[0] || false;
        },
        secondClass() {
            return this.reservations[1] || false;
        },
        thirdClass() {
            return this.reservations[2] || false;
        },
        fourthClass() {
            return this.reservations[3] || false;
        },
        fifthClass() {
            return this.reservations[4] || false;
        },
        sixthClass() {
            return this.reservations[5] || false;
        },
    },
}
</script>