<template>
    <div class="gokhale-cal">
        <private-foundation-course-widget v-for="voucher in vouchers" :voucher="voucher" :key="voucher.code"></private-foundation-course-widget>
        <private-foundation-calendar v-if="showCalendar"></private-foundation-calendar>
    </div>
</template>

<script>
import PrivateFoundationCourseWidget from './PrivateFoundationCourses/Widget';
import PrivateFoundationCalendar from './PrivateFoundationCourses/Calendar';

export default {
    components: {
        PrivateFoundationCourseWidget,
        PrivateFoundationCalendar,
    },
    store: {
        slot: 'slot',
        teacher: 'teacher',
        numPeople: 'numPeople',
        vouchers: 'vouchers',
    },
    computed: {
        showCalendar() {
            if (this.slot == 0) {
                return false;
            }
            if (this.teacher == 0) {
                return false;
            }
            if (this.numPeople == 0) {
                return false;
            }
            return true;
        }
    },
}
</script>