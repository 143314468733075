<template>
    <div class="private-foundation-courses__class-slot">
        <template v-if="reservation !== false">
            <strong>{{ slotName }} Lesson:</strong>
            <span>{{ reservationStartTime }}</span>
            <a :href="detailsLink" :class="{'btn': true, 'btn-primary': true, 'private-foundation-courses__btn-past': voucher.past == 1}" v-if="detailsLink != ''">Details</a>
        </template>
        <template v-else>
            <button :disabled="voucher.past == 1" @click="selectSlot()" class="btn btn-primary">
                Book<br >
                {{ slotName }} Lesson
            </button>
        </template>
    </div>
</template>

<script>
import { getOrdinalIndicator } from '../../modules/helpers';
import moment from "moment";

export default {
    props: {
        reservation: {
            default: false,
            required: true,
        },
        slotNumber: {
            type: Number,
            default: 1,
            required: true,
        },
        voucher: {
            type: Object,
            default: false,
            required: true,
        },
    },
    methods: {
        selectSlot() {
            this.$store.slot = this.slotNumber;
            this.$store.reservation = this.voucher.id || false;
            this.$store.numPeople = this.voucher.people || false;
            this.$store.voucherCode = this.voucher.code || '';
        }
    },
    computed: {
        reservationStartTime() {
            return moment(this.reservation.start_time).format('ddd, MMM Do YYYY, h:mma');
        },
        slotName() {
            return this.slotNumber + getOrdinalIndicator(this.slotNumber);
        },
        detailsLink() {
            if (!this.reservation) {
                return '';
            }
            const id = this.reservation.id || '';
            const userId = this.reservation.user_id || '';
            const pppRS = this.reservation.ppp_rs || '';
            return '/user_dashboard?planyo_lang=en&mode=reservation_details&reservation_id=' + id + '&user_id=' + userId + '&ppp_rs=' + pppRS;
        },
        // link() {
        //     return '/booking?feedback_url=https%3A%2F%2Fgokhalemethod.com%2Fbooking&mode=reserve&planyo_lang=en&prefill=true&resource_id=' + encodeURIComponent(this.voucher.booking_resource);
        // },
    }
}
</script>