<template>
    <div :class="{ 'today': day.isToday, 'in-month': day.inMonth, ['day-' + day.dayOfWeek]: true }">
        <div v-if="day.isToday" class="today-bg"></div>
        <button :class="{ 'has-event': !noEvents, 'active': (day.id == selectedDay.id) }" :disabled="noEvents" @click="selectDay"><span>{{ day.day }}</span></button>
    </div>
</template>

<script>
    export default {
        props: {
            day: { type: Object, required: true},
        },
        store: {
            selectedDay() {
                return 'calendar.selectedDay';
            },
        },
        computed: {
            noEvents() {
                if (this.$store.calendar.events.length == 0) {
                    return true;
                }
                let hasEvent = false;
                this.$store.calendar.events.forEach((event, eventIndex) => {
                    if (event.days.length > 0) {
                        // Check each date associated with the event and see if it matches
                        event.days.forEach((date) => {
                            if (date == this.day.id) {
                                hasEvent = true;
                            }
                        });
                    }
                });
                return !hasEvent;
            },
            hasSelectedEvent() {
                if (this.noEvents) {
                    return false;
                }
                let isSelected = false;
                // Step through the events object that all components are sharing
                this.$store.calendar.events.forEach(globalEvent => {
                    // If we find a selected event
                    if (globalEvent.selected) {
                        // Then check all the days the event matches
                        globalEvent.days.forEach(date => {
                            // And if there is a match, then this selected day is selected
                            if (date == this.day.id) {
                                isSelected = true;
                            }
                        });
                    }
                });
                return isSelected;
            }
        },
        methods: {
            selectDay() {
                this.$store.calendar.selectDay(this.day);
            }
            // selectFirstEvent() {
            //     if (this.noEvents) {
            //         return false;
            //     }
            //     let firstEvent = false;
            //     let selectedEventId = '';
            //     // Step through each event (should be in ascending date order)
            //     this.$store.calendar.events.forEach((event, index) => {
            //         this.$store.calendar.events[index].selected = false;
            //         // Check each of the days for the event
            //         event.days.forEach(date => {
            //             // And if there is a match, then this selected day is selected
            //             if (date == this.day.id) {
            //                 this.$store.calendar.events[index].selected = !firstEvent;
            //                 if (firstEvent == false) {
            //                     this.$store.selectedEvent = event.id;
            //                     selectedEventId = event.id;
            //                 }
            //                 firstEvent = true;
            //             }
            //         });
            //     });

            // }
        },
    }
</script>
