<template>
    <div class="gokhale-cal">
        <h2>Book {{ slotName}} lesson</h2>
        <div class="gokhale-cal__loading" v-if="loading">
            <img :src="url + '/svg/loading.svg'" class="gokhale-cal__loading-icon" alt="Loading">
        </div>
        <div class="flex-row">
            <div class="planyo-calendar-and-list">
                <event-calendar></event-calendar>
                <event-list></event-list>
            </div>
            <div class="event-detail-container">
                <event-detail></event-detail>
            </div>
        </div>
    </div>
</template>

<script>
const Vue = require('vue');
import EventCalendar from '../EventCalendar.vue';
import EventList from './EventList.vue';
import EventDetail from './EventDetail.vue';
import { getOrdinalIndicator } from '../../modules/helpers';

export default {
    data() {
        return {
            url: process.env.MIX_APP_BASE_URL,
        };
    },
    store: {
        loading: 'calendar.loading',
        slot: 'slot',
        numPeople: 'numPeople',
    },
    components: {
        EventCalendar,
        EventList,
        EventDetail,
    },
    created() {
        this.$store.calendar.update();
    },
    computed: {
        slotName() {
            return this.slot + getOrdinalIndicator(this.slot);
        },
    },
}
</script>
