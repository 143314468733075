require('./bootstrap');

const Vue = require('vue');
import VueStash from 'vue-stash';
import Vuebar from 'vuebar';
import lineClamp from 'vue-line-clamp';
import VueScrollTo from 'vue-scrollto';
import Calendar from './classes/PrivateFoundationCalendarClass';

const calendar = new Calendar();

Vue.use(VueScrollTo);
Vue.use(VueStash);
Vue.use(Vuebar);
Vue.use(lineClamp, {});
Vue.component('private-foundation-courses', require('./components/PrivateFoundationCourses.vue').default);

const app = new Vue({
    el: '#dashboard-app',
    data: {
        store: {
            slot: 0,
            // This is the original reservation that bought the PFC. Used to find the exact PFL that can be booked
            reservation: 0,
            // Needed to pass into the booking form, and also affects the duration of booking slots
            numPeople: 0,
            voucherCode: '',
            calendar: calendar,
            reservations: [],
            vouchers: [],
            selectedResource: false,
        },
    },
    watch: {
        // When the "number of people" we're booking a lesson for changes, we'll need to update the calendar events since number of people affects the duration
        'store.numPeople': function(val) {
            this.$store.calendar.numPeople = val;
            // this.$store.calendar.getEvents();
        },
        // When the "course" we're booking a lesson for changes, we'll need to collect the lesson which is bookable from the
        'store.reservation': function(val) {
            this.$store.calendar.setReservation(val);
            // this.$store.calendar.getEvents();
        },
    },
    methods: {
        collectVouchers() {
            // console.log('collectVoucher');
            axios.get('/planyo/vouchers')
            .then(response => {
                // console.log('collectVoucher', response.data);
                this.$store.reservations = response.data.reservations || [];
                this.$store.vouchers = response.data.vouchers || [];
            })
            .catch(error => {
                console.error('resources/js/private-foundation-widget.js@collectVouchers()', error);
            })
        },
    },
    created() {
        this.collectVouchers();
    },
});
