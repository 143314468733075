export function getOrdinalIndicator(i = 0) {
    const j = i % 10,
        k = i % 100;
    if (j == 1 && k != 11) {
        return "st";
    }
    if (j == 2 && k != 12) {
        return "nd";
    }
    if (j == 3 && k != 13) {
        return "rd";
    }
    return "th";
}

/**
 * Insert a Node/Element after another
 * @param {Node} whatToInsert
 * @param {Node} whereToInsert
 */
export function insertAfter(whatToInsert, whereToInsert) {
    if (!whereToInsert.parentNode) {
        return;
    }
    whereToInsert.parentNode.insertBefore(whatToInsert, whereToInsert.nextSibling);

}

/**
 * Get the HTML for a loading spinner
 */
export function getLoadingSpinnerHTML() {
    return `<?xml version="1.0" encoding="UTF-8" standalone="no"?><svg xmlns:svg="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.0" width="64px" height="64px" viewBox="0 0 128 128" xml:space="preserve"><g><path d="M75.4 126.63a11.43 11.43 0 0 1-2.1-22.65 40.9 40.9 0 0 0 30.5-30.6 11.4 11.4 0 1 1 22.27 4.87h.02a63.77 63.77 0 0 1-47.8 48.05v-.02a11.38 11.38 0 0 1-2.93.37z" fill="#000000" fill-opacity="1"/><animateTransform attributeName="transform" type="rotate" from="0 64 64" to="360 64 64" dur="1800ms" repeatCount="indefinite"></animateTransform></g></svg>`;
}

/**
 * Get an Element for a loading spinner
 */
export function getLoadingElement() {
    const element = document.createElement('div');
    element.classList.add('planyo-experts__loading');
    element.innerHTML = getLoadingSpinnerHTML();
    return element;
}

export default { getOrdinalIndicator, insertAfter, getLoadingSpinnerHTML };