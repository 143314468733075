<template>
    <div class="planyo-event-calendar">
        <table class="table text-center navigation planyo-event-calendar__heading">
            <thead>
                <tr>
                    <th>
                        <button class="nav-button previous" @click="previousMonth()">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"/>
                                <path d="M0 0h24v24H0z" fill="none"/>
                            </svg>
                        </button>
                    </th>
                    <th><h2 class="calendar-title">{{ getMonthName() }}</h2></th>
                    <th>
                        <button class="nav-button next" @click="nextMonth()">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"/>
                                <path d="M0 0h24v24H0z" fill="none"/>
                            </svg>
                        </button>
                    </th>
                </tr>
            </thead>
        </table>
        <div class="calendar-headings">
            <div class="day day-0">SUN</div>
            <div class="day day-1">MON</div>
            <div class="day day-2">TUE</div>
            <div class="day day-3">WED</div>
            <div class="day day-4">THU</div>
            <div class="day day-5">FRI</div>
            <div class="day day-6">SAT</div>
        </div>
        <div class="calendar-content">
            <event-calendar-week v-for="week in calendar" :week="week" :key="week.id"></event-calendar-week>
        </div>
        <div class="gokhale-cal__loading gokhale-cal__loading--overlay" v-if="loading">
            <img :src="url + '/svg/loading.svg'" class="gokhale-cal__loading-icon" alt="Loading">
        </div>
    </div>
</template>

<script>
import EventCalendarWeek from "./EventCalendarWeek";

export default {
    data() {
        return {
            url: process.env.MIX_APP_BASE_URL,
        };
    },
    components: {
        EventCalendarWeek,
    },
    store: {
        calendar() {
            return 'calendar.calendar';
        }
    },
    computed: {
        loading() {
            return this.$store.calendar.loading;
        }
    },
    methods: {
        getMonthName() {
            return this.$store.calendar.month.format('MMM YYYY');
        },
        previousMonth() {
            this.$store.calendar.previousMonth();
        },
        nextMonth() {
            this.$store.calendar.nextMonth();
        }
    }
}
</script>
