<template>
    <div class="week">
        <event-calendar-day class="day" v-for="day in week" :key="day.id" :day="day"></event-calendar-day>
    </div>
</template>

<script>
import EventCalendarDay from "./EventCalendarDay";
export default {
    components: {
        EventCalendarDay,
    },
    props: {
        week: { type: Array, required: true},
    },
}
</script>
