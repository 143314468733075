<template>
    <div class="planyo-event-detail">
        <template v-if="selectedEvent">
            <div v-if="photo" class="event-image">
                <div :style="{ 'background-image': 'url(' + photo +')' }" class="d-none d-sm-block"></div>
                <img v-bind:src="photo" class="img-fluid d-block d-sm-none" />
            </div>
            <div class="event-detail-padding">
                <div class="event-detail-content">
                    <div class="event-detail-title">
                        <p>Teacher:<br><strong>{{ teacherName }}</strong></p>
                        <p>Lesson type:<br><strong>{{ lessonType }}</strong></p>
                        <p>Location:<br><strong>{{ location }}</strong></p>
                        <p>Date and time:<br><strong>{{ startTime }}</strong></p>
                    </div>
                    <div class="event-detail-description">
                        <a :href="link" class="btn btn-primary">Book Lesson</a>
                    </div>
                </div>
            </div>
        </template>
        <template v-else>
            <div class="event-detail-padding">
                <div class="event-detail-content">
                    <div class="event-detail-title">
                        {{ reasonForNoEvents }}
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
    import moment from 'moment';

    export default {
        store: {
            selectedResource: 'selectedResource',
            bookableResourceID: 'calendar.bookableResourceID',
            numPeople: 'calendar.numPeople',
            voucherCode: 'voucherCode',
            selectedEvent: 'calendar.selectedEvent',
        },
        computed: {
            haveEventsThisMonth() {
                return (this.$store.calendar.events.length > 0) ? true : false;
            },
            reasonForNoEvents() {
                if (this.$store.calendar.bookableResourceID == '') {
                    return 'Please select a location to view upcoming availabilty';
                }
                if (!this.haveEventsThisMonth) {
                    return 'There are no lessons scheduled this month, please check other months';
                }
                if (this.$store.calendar.selectedDay === false && this.$store.calendar.todaysEvents.length == 0) {
                    return 'Please select a day of the month to view upcoming availability';
                }
                if (this.$store.calendar.todaysEvents.length == 0) {
                    return 'There are no lessons scheduled on this day, please select a different day';
                }
                return '';
            },
            duration() {
                if (this.selectedEvent.duration) {
                    return parseFloat(this.selectedEvent.duration) / 60;
                }
                return 0.75;
            },
            link() {
                if (this.$store.calendar.selectedResource && this.selectedEvent && this.$store.calendar.selectedResource.planyo_id) {
                    let timeAsDecimal = 0;
                    timeAsDecimal = this.startTimeMoment.hours() + (this.startTimeMoment.minutes() / 60);
                    return '/booking?feedback_url=https%3A%2F%2Fgokhalemethod.com%2Fbooking&mode=reserve&planyo_lang=en&prefill=true&one_date=' + encodeURIComponent(this.startTimeMoment.format('DD MMM YYYY')) + '&start_time=' + timeAsDecimal + '&rental_time_value=' + this.duration + '&rental_prop_persons=' + this.numPeople + '&resource_id=' + encodeURIComponent(this.bookableResourceID) + '&rental_prop_voucher=' + encodeURIComponent(this.voucherCode) + '&rental_prop_Teacher=' + encodeURIComponent(this.teacherName);
                }
                return '/booking';
            },
            startTimeMoment() {
                if (this.selectedEvent && this.selectedEvent.start_time) {
                    return moment(this.selectedEvent.start_time.date);
                }
                return moment();
            },
            startTime() {
                return this.startTimeMoment.format("ddd, MMM Do YYYY, HH:mm");
            },
            teacherName() {
                return this.$store.calendar.selectedResource.name || '-';
            },
            lessonType() {
                return this.$store.calendar.selectedResource.prop_lesson_type || 'Private Foundations Lesson';
            },
            location() {
                return this.$store.calendar.selectedResource.prop_location || '-';
            },
            photo() {
                return this.$store.calendar.selectedResource.photo || false;
            },
        },
    }
</script>
